import React from 'react';

function PrivacyPolicy() {
    return (

<body>
    <h1>Privacy Policy for Cheapest Pint UK</h1>
    <p><strong>Last updated:</strong> 5 October 2024</p>

    <p>Welcome to Cheapest Pint UK! We are committed to safeguarding your privacy while providing you with the best service to find the most affordable pints in the UK. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our web app.</p>

    <h2>1. Information We Collect</h2>

    <p><strong>1.1. Location Data:</strong> To enhance your experience by showing relevant deals and discounts near you, we use Google Location Services. This allows us to track your location in real-time or when you provide location data through the app. This data is used solely for the purpose of providing you with location-based services and is not shared with third parties.</p>

    <p><strong>1.2. Advertisements:</strong> We use advertisements from Adsterra to support our service. While ads may be displayed on our web app, we do not have direct access to or control over the data collected by Adsterra. Please refer to Adsterra’s privacy policy for more information on how they handle your data.</p>

    <p><strong>1.3. Usage Data:</strong> We may collect non-personally identifiable information about how you use our web app, such as your IP address, browser type, and operating system. This data helps us improve our services and ensure a better user experience.</p>

    <h2>2. How We Use Your Information</h2>

    <p><strong>2.1. To Provide and Improve Our Services:</strong> We use location data to offer you relevant pint deals based on your current location. Usage data helps us understand how our app is used and identify areas for improvement.</p>

    <p><strong>2.2. For Advertising:</strong> Advertisements are shown to support our service, but we do not have control over the data collected by third-party ad services like Adsterra.</p>

    <p><strong>2.3. To Communicate with You:</strong> We may occasionally send you updates about our services, new features, or promotional offers. You can opt-out of these communications at any time.</p>

    <h2>3. Data Security</h2>

    <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

    <h2>4. Data Retention</h2>

    <p>We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Data collected through location services and usage data may be retained for analytical purposes and to improve our services.</p>

    <h2>5. Third-Party Services</h2>

    <p><strong>5.1. Google Location Services:</strong> We use Google Location Services to provide you with location-based features. Google may collect and use location data in accordance with its own privacy policies. Please review Google’s privacy policy for more details.</p>

    <p><strong>5.2. Adsterra Advertisements:</strong> Our web app displays advertisements from Adsterra. Adsterra may collect information through these ads, and we recommend reviewing Adsterra’s privacy policy to understand how your data is handled. You can view their privacy policy here: <a href="https://adsterra.com/privacy-policy/" target="_blank">Adsterra Privacy Policy</a>.</p>

    <h2>6. Your Rights and Choices</h2>

    <p><strong>6.1. Access and Correction:</strong> You have the right to access and update your personal information. If you believe that any information we hold about you is incorrect or incomplete, please contact us to make the necessary corrections.</p>

    <p><strong>6.2. Opt-Out:</strong> You may opt-out of receiving promotional communications from us at any time by following the unsubscribe instructions provided in those communications.</p>

    <p><strong>6.3. Deletion of Data:</strong> You can request the deletion of your personal data by contacting us. We will take reasonable steps to honor your request, subject to any legal obligations we may have to retain certain information.</p>

    <h2>7. Changes to This Privacy Policy</h2>

    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our web app. Your continued use of our services after such changes constitutes your acceptance of the updated Privacy Policy.</p>

    <h2>8. Contact Us</h2>

    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

    <p><strong>Contact me</strong> <u><a href="https://forms.gle/vjH4QYoVMRz9mGdC8">here</a></u></p>

</body>
    );
}

export default PrivacyPolicy;
