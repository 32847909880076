import React, { useState } from 'react';

const filterOptions = [
    { id: 'wetherspoons', name: '🥄 Wetherspoons', value: 'show' },
    { id: 'beer-garden', name: '🪴 Beer Gardens', value: 'beer-garden' },
    { id: 'live-music', name: '🎶  Live Music', value: 'live-music' },
    { id: 'live-sport', name: '⚽️ Live Sports', value: 'live-sport' },
  ];
  
  export default function Filters({ onFilterChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
      wetherspoons: 'show',
      amenities: [],
    });
  
    const handleFilterChange = (filterId, value) => {
      setSelectedFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        if (filterId === 'wetherspoons') {
          newFilters.wetherspoons = newFilters.wetherspoons === 'show' ? 'hide' : 'show';
        } else {
          if (newFilters.amenities.includes(value)) {
            newFilters.amenities = newFilters.amenities.filter((item) => item !== value);
          } else {
            newFilters.amenities = [...newFilters.amenities, value];
          }
        }
        console.log("New filters:", newFilters); // Log new filters
        onFilterChange(newFilters);
        return newFilters;
      });
    };

  return (
    <div className="w-full relative inline-block text-left" style={{ zIndex: 50 }}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          Pub Filters
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
  className="absolute right-0 mt-2 w-80 sm:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
  style={{ zIndex: 50 }}
>
  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
    {filterOptions.map((option) => (
      <div key={option.id} className="px-4 py-2">
        <label className="flex items-center">
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name={option.id}
              id={option.id}
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              checked={
                option.id === 'wetherspoons'
                  ? selectedFilters.wetherspoons === 'show'
                  : selectedFilters.amenities.includes(option.value)
              }
              onChange={() => handleFilterChange(option.id, option.value)}
            />
            <label
              htmlFor={option.id}
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <span className="ml-2">{option.name}</span>
        </label>
      </div>
    ))}
  </div>
</div>

      )}
    </div>
  );
}