import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { formatDistanceToNow } from "date-fns";
import LazyBeerLogo from "./LazyBeerLogo";


// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

// Check if Firebase is not already initialized
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}

const CheapestPintSection = () => {
  const [cheapestPints, setCheapestPints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCheapestPints = async () => {
      try {
        const snapshot = await firebase
          .database()
          .ref("beerPrices")
          .orderByChild("price")
          .limitToFirst(3)
          .once("value");
    
        const pints = [];
        snapshot.forEach((childSnapshot) => {
          pints.push(childSnapshot.val());
        });

        // Group pints by price to handle ties
        const groupedPints = pints.reduce((acc, pint) => {
          (acc[pint.price] = acc[pint.price] || []).push(pint);
          return acc;
        }, {});

        setCheapestPints(Object.values(groupedPints));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching pint data:", error);
        setLoading(false);
      }
    };

    fetchCheapestPints();
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Unknown";
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const renderPintRow = (pint, index, isFirst) => (
    <div key={`${pint.pub}-${pint.beerType}`} className={`flex flex-col sm:flex-row items-start sm:items-center py-3 ${isFirst ? 'text-base' : 'text-sm'} ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
      <div className="items-center w-full sm:w-1/2 mb-2 sm:mb-0 p-2 flex">
        {/* <LazyBeerLogo beer={pint.beerType} size={isFirst ? 48 : 32} /> */}
        <div>
          <div className={`font-extrabold ${isFirst ? 'text-4xl' : 'text-xl'} mr-3`}>£{pint.price.toFixed(2)}</div>
          <div className="font-medium">{pint.beerType}</div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 pl-4 sm:pl-0">
        <a
          className="text-green-600 hover:text-green-800 underline block mb-1"
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(pint.pub)}`}
          target="_blank"
          rel="noreferrer"
        >
          {pint.pub}
        </a>
        <div className={`text-gray-500 ${isFirst ? 'text-sm' : 'text-xs'}`}>
          {formatTimestamp(pint.timestamp)}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full mb-2 sm:mb-4 p-4 sm:p-4 bg-white rounded-lg border">
      <h2 className="text-2xl font-bold mb-4">Cheapest pint leaderboard</h2>
      {loading ? (
        <p>Loading...</p>
      ) : cheapestPints.length > 0 ? (
        <div className="space-y-4">
          {cheapestPints.map((pintsGroup, groupIndex) => (
            <div key={groupIndex}>
              {pintsGroup.length > 1 && (
                <p className="font-semibold text-sm mb-2 text-gray-600">
                  {pintsGroup.length} tied
                </p>
              )}
              <div className="bg-gray-100 rounded-lg overflow-hidden">
                {pintsGroup.map((pint, index) => renderPintRow(pint, groupIndex * pintsGroup.length + index, groupIndex === 0))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No cheapest pints available</p>
      )}
    </div>
  );
};

export default CheapestPintSection;