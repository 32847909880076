import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { formatDistanceToNow } from 'date-fns';
import AdsterraAd from './AdsterraAd';
import { getGuinnessRatingLabel } from './utils/guinnessRating';


const mapContainerStyle = {
  width: '100%',
  height: '300px'
};



const PubPage = ({ pubs }) => {
  const { pubId } = useParams();
  const [showAllPints, setShowAllPints] = useState(false);

  if (!pubs || pubs.length === 0) {
    return <div>Loading...</div>;
  }

  const pub = pubs.find(p => p.id === pubId);
  const pubName = pub.pub.split(',')[0]; // Extracting the pub name before the first comma

  if (!pub) {
    return <div>Pub not found</div>;
  }

  const center = {
    lat: pub.lat,
    lng: pub.lng
  };

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${pub.pub}, ${pub.roadName}, ${pub.town}`)}`;

  const beerTypeCounts = pub.pints.reduce((acc, pint) => {
    acc[pint.beerType] = (acc[pint.beerType] || 0) + 1;
    return acc;
  }, {});

  const uniquePints = Object.values(pub.pints.reduce((acc, pint) => {
    if (!acc[pint.beerType] || new Date(pint.timestamp) > new Date(acc[pint.beerType].timestamp)) {
      acc[pint.beerType] = pint;
    }
    return acc;
  }, {})).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  const totalContributions = pub.pints.length;
  const mostRecentContribution = formatDistanceToNow(new Date(pub.pints[0].timestamp), { addSuffix: true });

  const displayedPints = showAllPints ? uniquePints : uniquePints.slice(0, 5);
  // console.log("Pints data:", pub.pints); // Log the pints data

  return (
    <>
      <Link to="/" className="inline-block mb-4 text-green-700 hover:text-green-600">
        ← Back
      </Link>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 pr-4">
          <h1 className="text-3xl font-bold mb-2">{pubName}</h1>
          <a 
            href={googleMapsLink} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-green-600 hover:text-green-700 underline"
          >
            {pub.pub}
          </a>
          <br/>
          <button 
            onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${pub.lat},${pub.lng}`, '_blank')}
            className="mb-8 bg-green-100 text-green-700 hover:bg-green-200 text-sm mt-4 font-semibold py-2 px-4 rounded"
          >
            Get Directions  ↗️
          </button>
          <p>🪴 Beer Garden: <b className={pub.beerGarden ? 'text-green-600' : 'text-slate-500'}>{pub.beerGarden ? 'Yes' : 'Not reported'}</b></p>
          <p>⚽️ Live Sports: <b className={pub.liveSports ? 'text-green-600' : 'text-slate-500'}>{pub.liveSports ? 'Yes' : 'Not reported'}</b></p>
          <p>🎶 Live Music: <b className={pub.liveMusic ? 'text-green-600' : 'text-slate-500'}>{pub.liveMusic ? 'Yes' : 'Not reported'}</b></p>
          <p>🍻 Happy Hour: <b className={pub.happyHour ? 'text-green-600' : 'text-slate-500'}>{pub.happyHour ? 'Yes' : 'Not reported'}</b></p>
          <AdsterraAd adKey="e4dee8def6985131de329c25e68634a3" width={320} height={50} />

          {pub.happyHour && (
            <div className="mt-4">
              <p className="font-semibold">Happy Hour:</p>
              <p>{pub.happyHourDays.join(', ')}</p> {/* Display happy hour days */}
              <p>{pub.happyHourStart} - {pub.happyHourEnd}</p>

            </div>
          )}

          <h2 className="text-xl font-bold mt-8">Pints recorded:</h2>
          <p className="text-sm">Most recent contribution: {mostRecentContribution}</p>
          <p className='text-sm mb-4'>Total contributions: {totalContributions}</p>
          <div className='divide-y'></div>
          <ul>
            {displayedPints.map((pint, index) => (
              <li className='mb-4' key={index}>
                <span className="font-semibold mr-2">
                  {beerTypeCounts[pint.beerType] >= 3 ? '⭐️' : '🍺'} {pint.beerType}: £{pint.price.toFixed(2)}
                </span>
                {pub.happyHour && (
                  <span className="bg-yellow-300 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900">
                    Happy Hour
                  </span>
              )}
              {pint.guinnessRating && pint.beerType.toLowerCase() === 'guinness' && (
                  <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2">
                    {getGuinnessRatingLabel(pint.guinnessRating)}
                  </span>
                )}
                <br />
                <span className="text-sm text-gray-500">
                  Recorded {formatDistanceToNow(new Date(pint.timestamp))} ago
                </span>
                <span className="text-sm text-gray-500 ml-2">
                  (Reported {beerTypeCounts[pint.beerType]} time{beerTypeCounts[pint.beerType] !== 1 ? 's' : ''})
                </span>
              </li>
            ))}
          </ul>
          
          {uniquePints.length > 5 && !showAllPints && (
            <button 
              onClick={() => setShowAllPints(true)}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Load More Pints
            </button>
          )}
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0">
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={15}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </>
  );
};

export default PubPage; 