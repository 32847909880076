import React, { useState, useEffect } from 'react';
  import { Link } from 'react-router-dom';
import { usePintContext } from './utils/PintContext';
import logo from './CheapestPint.svg';

const Navbar = () => {
  const { reportedPints, currentLevel } = usePintContext();
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const hideNavbarTooltip = localStorage.getItem('hideNavbarTooltip');
    setShowTooltip(hideNavbarTooltip !== 'true');
  }, []);


  const handleCloseTooltip = () => {
    setShowTooltip(false);
    // Optionally, you can save this preference to localStorage
    localStorage.setItem('hideNavbarTooltip', 'true');
  };

  const levelNumber = currentLevel.name.split(' - ')[0];


  return (
    <>
      <div className="bg-green-100 text-center p-2 lg:px-4">  
        <span className="font-semibold text-sm mr-2 text-left text-green-700 flex-auto">A pint with a ⭐️ has been reported at the same price, 3 or more times</span>
      </div>
      
      <nav className="container mx-auto max-w-7xl p-4 sm:px-4 sm:py-8 bg-white">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center pt-2 pb-6 sm:pb-0">
            <Link to="/">
              <img src={logo} alt="Cheapest pint logo " className="h-12 mr-2" />
            </Link>
            <div className="text-grey-900 text-2xl font-bold mb-4 sm:mb-2">
            </div>
          </div>
          
          <div className="flex items-center space-x-1">
        <div className="border border-gray-200 p-2 rounded-lg relative">
          <p className="text-gray-500 text-xs">Your pints:</p>
          <Link 
            to="/my-pints" 
            className="hover:underline text-green-700 hover:text-green-900 transition-colors duration-300"
          >
            🍺 {reportedPints} →
          </Link>
          {showTooltip && (
            <div className="absolute top-14 w-52 transform -translate-x-16 mt-1 px-3 py-2 bg-gray-800 text-white text-xs rounded shadow-lg">
                <div className="align-middle">
                <p>You can now save your pints! </p>
              <button 
                onClick={handleCloseTooltip}
                className="absolute top-1 right-2 text-white hover:text-gray-300"
                aria-label="Close tooltip"
              >
                ✕
              </button>
            </div>
            </div>
          )}
        </div>
        <div className="border border-gray-200 p-2 rounded-lg">
              <p className="text-gray-500 text-xs">Level:</p>
              <span className="text-green-700">
              {levelNumber}
              </span>
            </div>
            <a 
              href="#beerForm" 
              className="inline-flex items-center px-6 py-4 bg-green-100 rounded-lg text-green-700 font-semibold text-sm text-center hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-300" 
              aria-label="Submit a pint"
            >
              Submit a pint 🍺
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;