import React, { useState, useEffect } from 'react';
import { getBeerLogoPath } from './beerTypes';

const LazyBeerLogo = ({ beer, size = 24 }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const src = getBeerLogoPath(beer);
        setImageSrc(src);
      } catch (error) {
        console.error("Error loading image:", error);
        setImageSrc(getBeerLogoPath('default-beer'));
      }
    };

    loadImage();
  }, [beer]);

  if (!imageSrc) {
    return (
      <div
        style={{
          width: size,
          height: size,
          backgroundColor: '#f0f0f0',
          borderRadius: '50%',
          marginRight: 8,
        }}
      />
    );
  }

  return (
    <img
      src={imageSrc}
      alt={`${beer} logo`}
      style={{
        width: size,
        height: size,
        marginRight: 8,
        borderRadius: '50%',
        objectFit: 'cover',
      }}
    />
  );
};

export default LazyBeerLogo;