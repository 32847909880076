import React, { useEffect } from 'react';

const AdsterraAd = ({ adKey, width, height }) => {
  useEffect(() => {    
    // Create the first script for ad options
    const scriptOptions = document.createElement('script');
    scriptOptions.type = 'text/javascript';
    scriptOptions.innerHTML = `
      atOptions = {
        'key' : '${adKey}',
        'format' : 'iframe',
        'height' : ${height},
        'width' : ${width},
        'params' : {}
      };
    `;

    // Create the second script for invoking the ad
    const scriptInvoke = document.createElement('script');
    scriptInvoke.type = 'text/javascript';
    scriptInvoke.src = `//www.topcreativeformat.com/${adKey}/invoke.js`;

    // Append both scripts to the ad container
    const adContainer = document.getElementById(`ad-${adKey}`);
    if (adContainer) {
      adContainer.appendChild(scriptOptions);
      adContainer.appendChild(scriptInvoke);
    } else {
    }

    // Cleanup on component unmount
    return () => {
      if (adContainer) {
        adContainer.innerHTML = ''; // Clear the ad when unmounting
      }
    };
  }, [adKey, width, height]);

  return <div id={`ad-${adKey}`} style={{ textAlign: 'center', margin: '20px 0' }}></div>;
};

export default AdsterraAd;
