import React, { useState, useEffect } from "react";
import Select from "react-select";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, orderByChild, equalTo, get } from 'firebase/database';
import { query } from 'firebase/database'; // Add this import statement
import beerTypes from "./beerTypes"; // Import the beerTypes array
import { formatDistanceToNow } from "date-fns";



// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
    databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "average-pint-london",
    storageBucket: "average-pint-london.appspot.com",
    messagingSenderId: "284009350282",
    appId: "1:284009350282:web:ceec7375a8a98f60e81973",
    measurementId: "G-3DE63Z7MQE"
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };
  
  const CheapestPintSelect = ({ selectedBeerType, setSelectedBeerType }) => {
    const [cheapestPint, setCheapestPint] = useState(null);
    const [defaultBeerType, setDefaultBeerType] = useState(null); // Add this line
    const firebaseApp = initializeApp(firebaseConfig);
    const db = getDatabase(firebaseApp);
  
    const fetchCheapestPriceFromFirebase = async (beerType) => {
      try {
        // console.log("Fetching cheapest price for beer type:", beerType);
  
        const beerPricesRef = ref(db, "beerPrices");
        const q = query(beerPricesRef, orderByChild("beerType"), equalTo(beerType.value));
        const snapshot = await get(q);
  
        // console.log("Snapshot:", snapshot.val());
  
        if (snapshot.exists()) {
            let cheapestPrice = null;
            let pub = null;
            let timestamp = null;
            snapshot.forEach((childSnapshot) => {
              const beerData = childSnapshot.val();
              if (cheapestPrice === null || beerData.price < cheapestPrice) {
                cheapestPrice = beerData.price;
                pub = beerData.pub;
                timestamp = beerData.timestamp; // Add this line
              }
            });
          
            // console.log("Cheapest Price:", cheapestPrice);
            setCheapestPint({ price: cheapestPrice, pub, timestamp }); // Include the timestamp here
          } else {
            // console.log("No data found for selected beer type.");
            setCheapestPint(null);
          }
      } catch (error) {
        // console.error("Error fetching cheapest price:", error);
        setCheapestPint(null);
      }
    };
    
  
    useEffect(() => {
      const defaultBeerType = beerTypes.find((beer) => beer.label === "Guinness");
      setDefaultBeerType(defaultBeerType);
      setSelectedBeerType(defaultBeerType);
      }, []);
      
    
      useEffect(() => {
        if (selectedBeerType) {
          fetchCheapestPriceFromFirebase(selectedBeerType);
        }
      }, [selectedBeerType]);
  
    // console.log("Selected Beer Type:", selectedBeerType);
    // console.log("Cheapest Pint State:", cheapestPint);
    
    return (
      <div className="rounded-lg border bg-slate-50 border-gray-200 p-4 sm:p-6 w-full mb-4 sm:mb-4">
        {/* Render the Select component for beer type */}
        <h2 className="text-base sm:text-2xl font-bold mb-4 sm:mb-4">Cheapest price of a</h2>
        <Select
          id="beerType"
          name="beerType"
          options={beerTypes}
          value={selectedBeerType}
          onChange={(selectedOption) => {
            setCheapestPint(null); // Clear cheapestPint when selecting a new beer type
            setSelectedBeerType(selectedOption); // Update the selected beer type using the prop
          }}
          className="w-full mr-2 sm:mr-4 mb-4 sm:mb-4"
          placeholder="Select a beverage"
        />
        {cheapestPint ? (
          <div>
            {/* Render cheapest pint details */}
            <p className="text-xs sm:text-s font-m">is currently</p>
            <p className="text-4xl sm:text-4xl font-extrabold">£{cheapestPint.price}</p>
            <a className="text-blue-900 underline" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(cheapestPint.pub)}`} target="_blank" rel="noopener noreferrer">
            <p className="mb-4">{cheapestPint.pub}</p></a>            {/* Other cheapest pint details */}
            <p className="text-gray-500 text-sm">Last recorded: {formatTimestamp(cheapestPint.timestamp)}</p> {/* Display the timestamp */}

          </div>
        ) : (
          <p></p>
        )}
      </div>
    );
  };
  
  export default CheapestPintSelect;
