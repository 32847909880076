import React, { useEffect, useState } from "react";
import Select from "react-select";
import beerTypes from "./beerTypes";
import "./output.css";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, push, off } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import BeerPriceForm from "./BeerPriceForm";
import PricesTable from "./PricesTable";
import CheapestPintSection from "./CheapestPintSection";
import CheapestPintSelect from "./CheapestPintSelect";
import CheapestPintMap from "./CheapestPintMap";
import FAQs from "./faqs";
import CookieConsent from "react-cookie-consent";
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import Map from "./MapModal";
import CookieModal from "./CookieModal";
import { useCookieConsent } from './utils/cookieManagement';
import PubPage from './PubPage';
import AdsterraAd from './AdsterraAd';
import CheapestPintChart from "./CheapestPintChart";
import AveragePintChart from "./AveragePintChart";
import MyPints from './MyPints';
import { PintProvider } from './utils/PintContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const database = getDatabase(firebaseApp);
const pricesRef = ref(database, "beerPrices");

// Define the function to calculate average price for a specific beer type
const calculateAveragePriceForBeerType = (beerType, prices) => {
  const filteredPrices = prices.filter((price) => price.beerType === beerType);
  if (filteredPrices.length === 0) return 0;

  const total = filteredPrices.reduce((sum, price) => sum + price.price, 0);
  return (total / filteredPrices.length).toFixed(2);
};

const App = () => {
  const [selectedBeerType, setSelectedBeerType] = useState(null);
  const [selectedAverageBeerType, setSelectedAverageBeerType] = useState(null);
  const [averagePriceForBeerType, setAveragePriceForBeerType] = useState(0);
  const [prices, setPrices] = useState([]);
  const [pubs, setPubs] = useState([]); // Add state for pubs
  const [activeTab, setActiveTab] = useState("map"); // Add state for active tab

  const { isConsentGiven, isAllCookiesAccepted } = useCookieConsent();  

  useEffect(() => {
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      const priceData = data ? Object.values(data) : [];
      setPrices(priceData);

      // Process pubs data
      const pubMap = {};
      priceData.forEach((price) => {
        if (!pubMap[price.pub]) {
          pubMap[price.pub] = {
            ...price,
            pints: [],
            id: price.pub.replace(/\s+/g, '-').toLowerCase(), // Generate a unique ID for each pub
          };
        }
        pubMap[price.pub].pints.push({
          beerType: price.beerType,
          price: price.price,
          timestamp: price.timestamp,
        });
      });

      const updatedPubs = Object.values(pubMap).map((pub) => ({
        ...pub,
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lng),
        cheapestPint: pub.price,
        lastRecorded: pub.timestamp,
        beerGarden: pub.beerGarden,
        liveSports: pub.liveSports,
        liveMusic: pub.liveMusic,
      }));

      setPubs(updatedPubs);
    };

    onValue(pricesRef, onDataChange);

    return () => {
      off(pricesRef, onDataChange);
    };
  }, []);

  const handleAddPrice = (newPrice) => {
    const timestamp = new Date().toISOString();
    push(ref(database, "beerPrices"), { ...newPrice, timestamp })
      .then(() => {
        // console.log("Price added successfully.");
      })
      .catch((error) => {
        // console.error("Error adding price:", error);
      });
  };

  const calculateAveragePrice = () => {
    if (prices.length === 0) return 0;

    const total = prices.reduce((sum, price) => sum + price.price, 0);
    return (total / prices.length).toFixed(2);
  };

  const handleBeerTypeChange = (selectedOption) => {
    setSelectedBeerType(selectedOption);
    setAveragePriceForBeerType(calculateAveragePriceForBeerType(selectedOption?.value, prices));
  };

  const handleAverageBeerTypeChange = (selectedOption) => {
    setSelectedAverageBeerType(selectedOption);
    const averagePrice = calculateAveragePriceForBeerType(selectedOption?.value, prices);
    setAveragePriceForBeerType(averagePrice !== 0 ? averagePrice : "");
  };

  return (
    <PintProvider>
      <Router>
        <ToastContainer />
        <CookieModal />
        <Navbar />
        <main className="min-h-screen justify-center p-0 sm:p-4">
          <div className="container mx-auto max-w-7xl p-4 sm:px-6 mb-8 sm:py-8 bg-white rounded-lg">
            <Routes>
              <Route path="/" element={
                <>
                  <header className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-10 mb-8">
                    <div className="flex-1">
                      <h1 className="text-2xl pb-2 sm:text-5xl sm:te mb-4 sm:mb-2 font-black text-black-700 max-w-prose">
                        A crowdsourced guide to London and the UK's cheapest pint prices.
                      </h1>
                      <p className="max-w-prose text-slate-700"> 
                        Explore the cheapest pint prices across pubs in London and the UK. See their locations and other amenities like beer gardens, live music and live sport. Contribute your own pint prices too. We even went viral on reddit and <a href="https://secretldn.com/pint-price-map-london/" target="_blank" rel="noopener noreferrer" className="text-green-600 underline">Secret London</a>. If you've found this website useful, feel free to <a href="https://buymeacoffee.com/averagepint" target="_blank" rel="noopener noreferrer" className="text-green-600 underline">shout me a pint!</a>
                      </p>
                    </div>
                    <div className="flex flex-row gap-4 mt-4 sm:mt-0 sm:block">
                      <CheapestPintSection />
                    </div>
                  </header>
                  <div>
                    <AdsterraAd adKey="e4dee8def6985131de329c25e68634a3" width={320} height={50} />  
                  </div>
                  {/* Disclaimer message */}
                  <div className="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Disclaimer:</span>
                    <div>
                      <span className="font-medium">Disclaimer:</span> Pint prices listed here are crowdsourced and may not always be accurate or up-to-date. Please confirm with the venue for current pricing. Also, please be as honest as possible when submitting prices, it helps everyone!
                    </div>
                  </div>

                  <nav aria-label="Pint information tabs">
                    <ul className="flex flex-wrap -mb-px border-b border-gray-200" role="tablist">
                      <li className="me-2 text-bold" role="presentation">
                        <button
                          onClick={() => setActiveTab("map")}
                          className={`inline-block p-4 border-b-2 rounded-t-lg text-black ${activeTab === "map" ? "text-black text-green-600 border-green-600" : "text-black border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                          role="tab"
                          aria-selected={activeTab === "map"}
                          aria-controls="mapTab"
                          id="mapTabButton"
                        >
                          Pint Map 📍
                        </button>
                      </li>
                      <li className="me-2" role="presentation">
                        <button
                          onClick={() => setActiveTab("prices")}
                          className={`inline-block p-4 border-b-2 rounded-t-lg text-black ${activeTab === "prices" ? "text-black text-green-600 border-green-600" : "text-black border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                          role="tab"
                          aria-selected={activeTab === "prices"}
                          aria-controls="pricesTab"
                          id="pricesTabButton"
                        >
                          Pint list 📄
                        </button>
                      </li>
                    </ul>
                  </nav>

                  <section 
                    id="cheapestSection" 
                    className="w-full mb-6"
                    aria-labelledby={activeTab === "map" ? "mapTabButton" : "pricesTabButton"}
                  >
                    {activeTab === "map" ? (
                      <div id="mapTab" role="tabpanel" aria-labelledby="mapTabButton">
  <div className="map-container" style={{ aspectRatio: '16/9', background: '#fafafa' }}>
    <CheapestPintMap />
                        </div>
                      </div>
                    ) : (
                      <div id="pricesTab" role="tabpanel" aria-labelledby="pricesTabButton">
                        <PricesTable prices={prices} />
                      </div>
                    )}
                  </section>

                  <section id="beerForm" className="grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2">
                    <div id="beerFormContainer">
                      <BeerPriceForm onAddPrice={handleAddPrice} />
                    </div>

                    <section className="gap-2 sm:gap-4 sm:grid-cols-2">
                      <div className="text-xl font-bold mb-4">
                        <CheapestPintChart />
                      </div>
                      <AveragePintChart />

                      <CheapestPintSelect
                        firebaseConfig={firebaseConfig}
                        selectedBeerType={selectedBeerType}
                        setSelectedBeerType={setSelectedBeerType}
                      />
                      <div className="w-full mb-2 sm:mb-4 p-4 sm:p-8 bg-slate-50 rounded border">
                        <h2 className="text-base sm:text-2xl font-bold mb-2 sm:mb-4">Average price of a pint in the UK is currently</h2>
                        <p className="text-4xl sm:text-4xl font-extrabold">£{calculateAveragePrice()}</p>
                      </div>
                      <AdsterraAd adKey="8873da6124f0e434bf40ce9fe89064c4" width={250} height={300} />
                      <div className="rounded-lg border bg-slate-50 border-gray-200 p-4 sm:p-6 w-full mb-4 sm:mb-4">
                        <h2 className="text-base sm:text-2xl font-bold mb-4 sm:mb-4">Average price of a</h2>
                        <Select
                          id="averageBeerType"
                          name="averageBeerType"
                          options={beerTypes}
                          value={selectedAverageBeerType}
                          onChange={handleAverageBeerTypeChange}
                          className="w-full mr-2 sm:mr-4 mb-4 sm:mb-4"
                          placeholder="Select a beverage"
                          aria-label="Select beer type for average price"
                        />
                        <div className="w-full">
                          {averagePriceForBeerType ? (
                            <>
                              <p className="text-xs sm:text-s font-m">is currently</p>
                              <p className="text-4xl sm:text-4xl font-extrabold">£{averagePriceForBeerType}</p>
                            </>
                          ) : (
                            <p className="text-xs sm:text-s font-m">Select a beverage to see the average price</p>
                          )}
                        </div>
                      </div>
                    </section>
                  </section>
                  <FAQs />
                </>
              } />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/map" element={<Map />} />
              <Route path="/pub/:pubId" element={<PubPage pubs={pubs} />} />
              <Route path="/my-pints" element={<MyPints />} />
            </Routes>
          </div>
        </main>
        <footer className="mt-8 bg-gray-900">
            <div className="container mx-auto max-w-7xl p-2 sm:px-4 sm:py-8 rounded-lg">
              <nav aria-label="Footer Navigation">
                <ul className="flex flex-col gap-8 items-center sm:flex-row">
                <img src="./CPLogo.png" alt="CP logo" className="w-24" />
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdyHWtxGXpyDhx6d8hSgHdk_eDNzHLLUJhJuF3-PDb7_SVy5Q/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" className="block text-left text-white underline hover:underline" aria-label="Give feedback">
                      <span className="text-2xl" role="img" aria-hidden="true">🗣️</span> Give feedback
                    </a>
                  </li>
                  <li>
                    <a href="https://buymeacoffee.com/averagepint" target="_blank" rel="noopener noreferrer" className="block text-left text-white underline hover:underline" aria-label="Buy me a pint">
                      <span className="text-2xl" role="img" aria-hidden="true">🍺</span> Buy me a pint 
                    </a>
                  </li>
                  <li>
                  <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="block text-left text-white underline" aria-label="Buy me a pint">
                      <span className="text-2xl" role="img" aria-hidden="true"></span> Privacy Policy
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
        <a
          href="#beerForm"
          className="fixed bottom-6 left-1/2 transform -translate-x-1/2 rounded-full bg-green-600 text-white p-4 sm:hidden"
          aria-label="Go to submit a pint section"
          onClick={(e) => e.target.style.display = 'none'}
        >
          Submit a pint 🍺
        </a>
      </Router>
    </PintProvider>
  );
};

export default App;